// app/javascript/controllers/draggable_notification_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "handle"]

  connect() {
    // Initialize state
    this.isDragging = false;
    this.pendingAnimationFrame = null;

    // Ensure the element is set to fixed position
    this.element.style.position = 'fixed';

    // Apply hardware acceleration and initial opacity
    this.element.style.transform = 'translate3d(0, 0, 0)';
    this.element.style.willChange = 'transform';
    this.element.style.opacity = '0';

    // Add fade-in effect
    setTimeout(() => {
      this.element.style.opacity = '1';
    }, 10);

    // Load saved position
    this.loadSavedPosition();

    // Set up event handlers for the handle
    this.handleTarget.addEventListener('mousedown', this.onMouseDown.bind(this), { passive: false });
    this.handleTarget.addEventListener('touchstart', this.onTouchStart.bind(this), { passive: false });

    // Set up window resize handler
    this.onResize = this.onResize.bind(this);
    window.addEventListener('resize', this.onResize);
  }

  loadSavedPosition() {
    const savedPosition = localStorage.getItem('pendingTicketsPosition');

    if (savedPosition) {
      try {
        const { x, y } = JSON.parse(savedPosition);

        // Check if position is valid for current viewport
        if (this.isPositionVisible(x, y)) {
          this.applyPosition(x, y);

          // Remove default positioning classes
          this.element.classList.remove('top-4', 'right-4', 'md:top-6', 'md:right-6');
        } else {
          // Reset to default if position is invalid
          this.resetToDefaultPosition();
        }
      } catch (error) {
        console.error('Error loading position:', error);
        this.resetToDefaultPosition();
      }
    }
  }

  applyPosition(x, y) {
    // Use transform for better performance
    this.element.style.transform = `translate3d(${x}px, ${y}px, 0)`;

    // Store current position for calculations
    this.currentX = x;
    this.currentY = y;
  }

  isPositionVisible(x, y) {
    // Get viewport dimensions (client width/height gives the actual viewport size)
    const viewportWidth = document.documentElement.clientWidth;
    const viewportHeight = document.documentElement.clientHeight;

    // Get element dimensions
    const width = this.element.offsetWidth || 280;
    const height = this.element.offsetHeight || 150;

    // Minimum visible area (in pixels)
    const minVisible = 80;

    // Check if enough of the element is visible in viewport
    return (
        x + width - minVisible >= 0 &&
        x + minVisible <= viewportWidth &&
        y + height - minVisible >= 0 &&
        y + minVisible <= viewportHeight
    );
  }

  resetToDefaultPosition() {
    // Clear saved position
    localStorage.removeItem('pendingTicketsPosition');

    // Reset to default position
    this.element.style.transform = 'translate3d(0, 0, 0)';
    this.element.style.left = '';
    this.element.style.top = '';
    this.element.classList.add('top-4', 'right-4', 'md:top-6', 'md:right-6');

    // Reset current position
    this.currentX = 0;
    this.currentY = 0;
  }

  savePosition() {
    const position = {
      x: this.currentX,
      y: this.currentY
    };

    localStorage.setItem('pendingTicketsPosition', JSON.stringify(position));
  }

  // Check boundaries and reset if needed
  checkVisibility() {
    if (!this.isPositionVisible(this.currentX, this.currentY)) {
      this.resetToDefaultPosition();
      return false;
    }

    return true;
  }

  // Mouse event handlers
  onMouseDown(event) {
    // Prevent default to avoid text selection
    event.preventDefault();

    const rect = this.element.getBoundingClientRect();

    // Get current position
    this.currentX = rect.left;
    this.currentY = rect.top;

    // Make sure default classes are removed
    this.element.classList.remove('top-4', 'right-4', 'md:top-6', 'md:right-6');

    // Calculate offset of mouse within element
    this.offsetX = event.clientX - rect.left;
    this.offsetY = event.clientY - rect.top;

    // Add visual feedback
    this.containerTarget.classList.add('scale-[1.02]', 'shadow-xl');

    // Improve performance during drag
    this.element.style.willChange = 'transform';

    // Disable text selection
    document.body.style.userSelect = 'none';

    // Store bound handlers for removal later
    this.boundMouseMove = this.onMouseMove.bind(this);
    this.boundMouseUp = this.onMouseUp.bind(this);

    // Set up mouse move and up handlers
    document.addEventListener('mousemove', this.boundMouseMove, { passive: false });
    document.addEventListener('mouseup', this.boundMouseUp);
  }

  onMouseMove(event) {
    // Prevent default
    event.preventDefault();

    // Calculate new position
    const newX = event.clientX - this.offsetX;
    const newY = event.clientY - this.offsetY;

    // Use requestAnimationFrame for smoother updates
    this.updateElementPosition(newX, newY);
  }

  updateElementPosition(x, y) {
    // Cancel any pending animation frame
    if (this.pendingAnimationFrame) {
      cancelAnimationFrame(this.pendingAnimationFrame);
    }

    // Schedule the update on the next animation frame
    this.pendingAnimationFrame = requestAnimationFrame(() => {
      this.currentX = x;
      this.currentY = y;
      this.applyPosition(x, y);
      this.pendingAnimationFrame = null;
    });
  }

  onMouseUp(event) {
    // Prevent default
    event.preventDefault();

    // Reset will-change to free up resources
    this.element.style.willChange = 'auto';

    // Remove visual feedback
    this.containerTarget.classList.remove('scale-[1.02]', 'shadow-xl');

    // Check if position is valid
    if (!this.checkVisibility()) {
      // Already reset in checkVisibility if invalid
    } else {
      // Save final position if valid
      this.savePosition();
    }

    // Re-enable text selection
    document.body.style.userSelect = '';

    // Remove event listeners
    document.removeEventListener('mousemove', this.boundMouseMove);
    document.removeEventListener('mouseup', this.boundMouseUp);
  }

  // Touch event handlers
  onTouchStart(event) {
    // Only handle single touches
    if (event.touches.length !== 1) return;

    // Prevent default to avoid scrolling
    event.preventDefault();

    // Get the touch
    const touch = event.touches[0];
    const rect = this.element.getBoundingClientRect();

    // Get current position
    this.currentX = rect.left;
    this.currentY = rect.top;

    // Make sure default classes are removed
    this.element.classList.remove('top-4', 'right-4', 'md:top-6', 'md:right-6');

    // Calculate offset of touch within element
    this.offsetX = touch.clientX - rect.left;
    this.offsetY = touch.clientY - rect.top;

    // Add visual feedback
    this.containerTarget.classList.add('scale-[1.02]', 'shadow-xl');

    // Improve performance during drag
    this.element.style.willChange = 'transform';

    // Set flag to enable touchmove handler
    this.isDragging = true;

    // Store bound handlers for removal later
    this.boundTouchMove = this.onTouchMove.bind(this);
    this.boundTouchEnd = this.onTouchEnd.bind(this);

    // Set up touch move and end handlers
    document.addEventListener('touchmove', this.boundTouchMove, { passive: false });
    document.addEventListener('touchend', this.boundTouchEnd);
    document.addEventListener('touchcancel', this.boundTouchEnd);
  }

  onTouchMove(event) {
    // Only process if dragging and single touch
    if (!this.isDragging || event.touches.length !== 1) return;

    // Prevent scrolling
    event.preventDefault();

    // Get the touch
    const touch = event.touches[0];

    // Calculate new position
    const newX = touch.clientX - this.offsetX;
    const newY = touch.clientY - this.offsetY;

    // Use requestAnimationFrame for smoother updates
    this.updateElementPosition(newX, newY);
  }

  onTouchEnd(event) {
    // Reset dragging flag
    this.isDragging = false;

    // Reset will-change to free up resources
    this.element.style.willChange = 'auto';

    // Remove visual feedback
    this.containerTarget.classList.remove('scale-[1.02]', 'shadow-xl');

    // Check if position is valid
    if (!this.checkVisibility()) {
      // Already reset in checkVisibility if invalid
    } else {
      // Save final position if valid
      this.savePosition();
    }

    // Remove event listeners
    document.removeEventListener('touchmove', this.boundTouchMove);
    document.removeEventListener('touchend', this.boundTouchEnd);
    document.removeEventListener('touchcancel', this.boundTouchEnd);
  }

  onResize() {
    // Check if current position is valid
    this.checkVisibility();
  }

  disconnect() {
    // Cancel any pending animation frame
    if (this.pendingAnimationFrame) {
      cancelAnimationFrame(this.pendingAnimationFrame);
    }

    // Clean up event listeners
    this.handleTarget.removeEventListener('mousedown', this.onMouseDown);
    this.handleTarget.removeEventListener('touchstart', this.onTouchStart);

    if (this.boundMouseMove) {
      document.removeEventListener('mousemove', this.boundMouseMove);
    }
    if (this.boundMouseUp) {
      document.removeEventListener('mouseup', this.boundMouseUp);
    }
    if (this.boundTouchMove) {
      document.removeEventListener('touchmove', this.boundTouchMove);
    }
    if (this.boundTouchEnd) {
      document.removeEventListener('touchend', this.boundTouchEnd);
      document.removeEventListener('touchcancel', this.boundTouchEnd);
    }

    window.removeEventListener('resize', this.onResize);

    // Reset body styles
    document.body.style.userSelect = '';
  }
}